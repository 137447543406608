import { connect } from "react-redux";

const PacketItemListModal = (props) => {
	return (
		<div id="modalItemList" className="modalWrapper" data-modalite-modal="">
			<div className="modalContent modalSizeMD" style={{ backgroundColor: "#F4F4F4" }}>
				<div className="modalContentHeader noBorder">
					<button className="closeModalBt" data-modalite-close=""></button>
				</div>
				<div className="modalContentBody">
					{props.item_info.length > 0 ? (
						<div className="container">
							{props.item_info.map((packet, i) => (
								<div className="cardGroup" key={i}>
									<p>Packet No : {packet.order_packet_id}</p>
									{packet.itemlist.map((item, j) => (
										<div className="card toPickupModal" key={j}>
											<div className="card-content">
												<div className="container">
													<div className="row">
														<div className="col-sm-2 productImg productImgCheckbox" style={{ paddingRight: "0px" }}>
															<img src={`https://static.oxinis.com/healthmug/image/product/${item.image}-100.jpg`} />
														</div>
														<div className="col-sm-10 productInfo">
															<p className={item.lowest_dimension == 0 ? "brandtextDanger" : null}>
																<b>{item.name}</b>
															</p>
															<p>
																SKU : {item.sku} <span className="orderId">{item.order_item_id}</span>
															</p>
															<div className="row toPickupModalPrice">
																<div className="col-xs-3">
																	<p>Mrp : {item.mrp}</p>
																</div>
																<div className="col-xs-3">
																	<p>Price: {item.price}</p>
																</div>
																<div className="col-xs-3">
																	<p>Qty : {item.qty}</p>
																</div>
																<div className="col-xs-3">
																	<p>Total : {item.total}</p>
																</div>
															</div>
															{item.resolution_date == null ? (
																<p className="brandtextDanger missingInfo">{item.issue_reason}</p>
															) : null}
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							))}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	item_info: state.common_store.item_info
});

export default connect(mapStateToProps)(PacketItemListModal);
