import { memo } from "react";

const QuickIcon = ({ time, style }) => {
	return (
		<div style={{ display: "inline-block", textAlign: "center", ...style }}>
			<img src="https://static.oxinis.com/healthmug/image/healthmug/quick.png" style={{ width: "70px", height: "auto" }} />
			{time ? <div className="brandtextOrange fontBold">{time}</div> : null}
		</div>
	);
};

export default memo(QuickIcon);
