import { ProjectUrl } from "~/env";

import {
	HUB_INFO_INIT_DATA,
	HUB_SELLER_RELOAD_DATA,
	HUB_SELLER_APPEND_DATA,
	HUB_SELLER_SEARCH_CHANGE,
	HUB_CONTACT_INFO_INIT_DATA
} from "~/actions/types";

/**
 * @description Saves new password of the user
 * @param {string} current_password
 * @param {string} new_password
 * @param {string} confirm_password
 * @param {Object} change_password_form (reference of form element)
 */
export const HubSavePassword = (current_password, new_password, confirm_password, change_password_form) => () => {
	const password_form = change_password_form;

	password_form.classList.add("validated");

	if (password_form.checkValidity() == true) {
		if (new_password === confirm_password) {
			fetch(`${ProjectUrl}/hub/setnewpassword`, {
				method: "POST",
				credentials: "include",
				headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
				body: JSON.stringify({ oldpassword: current_password, newpassword: new_password, confirmpassword: confirm_password })
			})
				.then((res) => (res.ok ? res.text() : Promise.reject(res)))
				.then((data) => {
					alertify.success("Password Changed");
				})
				.catch((err) => {
					err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
				});
		} else {
			alertify.error("New password and confirm password must be same");
		}
	}
};

/**
 * @description Function for Getting Initial Contact Info data
 */
export const HubGetContactInfoInitData = () => (dispatch, getState) => {
	nanobar.go(50);
	fetch(`${ProjectUrl}/hub/get-contact-info`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagesize: 20, pageno: 1, search: "" })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: HUB_CONTACT_INFO_INIT_DATA, data });
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

/**
 * @description Function for Getting Initial Hub Info data
 */
export const HubGetInfoInitData = () => (dispatch, getState) => {
	nanobar.go(50);
	fetch(`${ProjectUrl}/hub/get-hub-info`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagesize: 20, pageno: 1, search: "" })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: HUB_INFO_INIT_DATA, data });
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

/**
 * @description Function for Getting seller data w.r.t seller name and number on seller screen
 * @param {string} type (reload | append)
 */
export const HubGetSellersData = (type) => (dispatch, getState) => {
	const { hub_store } = getState();
	let { pagesize, pageno, search } = hub_store;

	if (type == "reload") pageno = 1;
	else pageno += 1;

	nanobar.go(50);

	fetch(`${ProjectUrl}/hub/getsellerdata`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ pagesize, pageno, search })
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: type == "reload" ? HUB_SELLER_RELOAD_DATA : HUB_SELLER_APPEND_DATA, data });
			nanobar.go(100);
		})
		.catch((err) => {
			nanobar.go(100);
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

/**
 * @description Sets seller name/mobile_number for upcoming search process on seller screen
 * @param {string} value
 */
export const HubSearchSeller = (value) => ({ type: HUB_SELLER_SEARCH_CHANGE, value });
